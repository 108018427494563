import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import {
  SimpleForm,
  TextInput,
  TextField,
  FunctionField,
  UrlField,
  required,
  Edit,
} from 'react-admin';

import { getComparison } from '../../shared/helpers/get-comparison.helper';
import { EditRequestStatusStyle } from './EditRequestStatus.style';
import { EditRequestStatusButton } from './EditRequestStatusButton';
import { FileUploadList } from './FileUploadList';
import { ShowIdentData } from './ShowIdentData';

export const EditRequestStatus = (props) => {
  const classes = EditRequestStatusStyle();
  const { record } = props;
  const {
    userCrefoId,
    comment,
    personMatchError,
    dataSource,
    identData,
    orderId,
    files,
    currentState,
  } = record;
  const [updatedComment, setUpdatedComment] = useState(comment ?? '');

  const [show, setShow] = useState(false);

  const changeShow = () => {
    setShow(!show);
  };

  const comparisonResult = getComparison(
    personMatchError?.personMatch,
    personMatchError?.stacktrace
  );

  if (!personMatchError) {
    return <></>;
  }

  return (
    <>
      <Edit {...props} title=' ' undoable={false}>
        <SimpleForm
          {...props}
          toolbar={
            <EditRequestStatusButton
              {...props}
              selectedId={orderId}
              comment={updatedComment}
              userCrefoId={userCrefoId}
              record={record}
            />
          }>
          <>
            <div
              style={{
                marginBottom: '20px',
                fontSize: '13px',
                opacity: '0.8',
              }}>
              Personenvergleich
            </div>
            <table
              style={{
                borderCollapse: 'separate',
                borderSpacing: '30px 10px',
              }}>
              <thead>
                <tr>
                  <th> </th>
                  <th>Ident</th>
                  <th>SSU</th>
                  <th>Check</th>
                </tr>
                <tr>
                  <td colSpan='4'>
                    <hr />
                  </td>
                </tr>
              </thead>

              <tbody>
                {comparisonResult.ident.map((item, index) => (
                  <>
                    <tr key={item.index}>
                      <td key={item.index} style={{ fontWeight: 'bold' }}>
                        {item.key}
                      </td>
                      <td key={item.index}>{item.value}</td>
                      <td key={item.index}>
                        {comparisonResult.ssu[index]?.value ?? ''}
                      </td>
                      <td key={item.index}>
                        {item.isMatching === undefined ? (
                          ''
                        ) : (
                          <>{item?.isMatching ? 'Ja' : 'Nein'}</>
                        )}
                      </td>
                    </tr>
                    <tr key={item.index}>
                      <td key={item.index} colSpan='4'>
                        <hr key={item.index} />
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </>

          <FunctionField
            label='Ausweisdokument'
            textAlign='left'
            render={() => {
              // eslint-disable-next-line react/destructuring-assignment
              const docType = props.record.documentType;
              return `${
                docType === '' || docType === null ? 'nicht angegeben' : docType
              }`;
            }}
          />

          {dataSource !== 'itm_videoident' ? (
            ''
          ) : (
            <UrlField source='identVideo' label='IDnow Video' target='_blank' />
          )}

          <UrlField source='rawDataSSU' label='Rohdaten SSU' target='_blank' />

          <FunctionField
            label='Rohdaten Ident-Provider'
            textAlign='left'
            render={() => (
              <>
                <Button className={classes.button} onClick={changeShow}>
                  {show === false ? 'Anzeigen' : 'Verstecken'}
                </Button>
                {show ? <ShowIdentData identData={identData} /> : ''}
              </>
            )}
          />
          <div style={{ height: '20px' }} />
          {!!files.length && (
            <FunctionField
              label='Hochgeladene Dateien'
              textAlign='left'
              render={() => (
                <FileUploadList
                  files={files}
                  comment={currentState?.payload?.value?.comment}
                />
              )}
            />
          )}
          {!!files.length && (
            <span className={classes.span}>Bearbeitungshinweise</span>
          )}
          {!!files.length && (
            <FunctionField
              label='Kommentar:'
              textAlign='left'
              render={() => `${currentState?.payload?.comment}`}
            />
          )}
          {!!files.length && (
            <FunctionField
              label='Korrekturverfahren:'
              textAlign='left'
              render={() => `${currentState?.payload?.identProvider}`}
            />
          )}
          <div style={{ height: '20px' }} />
          <TextField
            source='stateReason'
            label='Status'
            inputlabelprops={{ className: classes.textFieldLabel }}
            className={classes.textField}
          />

          <TextInput
            validate={[required(' ')]}
            source='updatedComment'
            label='Begründung für: Ausstellung / Ablehnung / Zurücksetzung'
            inputlabelprops={{ className: classes.textInputLabel }}
            className={classes.textInput}
            onChange={(e) => setUpdatedComment(e.target.value)}
          />
        </SimpleForm>
      </Edit>
    </>
  );
};

EditRequestStatus.defaultProps = {
  record: {
    comment: '',
    orderId: '',
    objectId: '',
    userCrefoId: '',
    documentType: '',
    identVideo: '',
    rawDataIdNow: '',
    rawDataSSU: '',
    files: [],
    currentState: undefined,
    personMatchError: {
      did: '',
      personMatch: {},
      stacktrace: [
        {
          name: '',
          isMatching: false,
          compared: {
            valueA: '',
            valueB: '',
          },
        },
      ],
    },

    dataSource: '',
    identData: {},
  },
};

EditRequestStatus.propTypes = {
  record: PropTypes.shape({
    comment: PropTypes.string,
    objectId: PropTypes.string,
    userCrefoId: PropTypes.string,
    orderId: PropTypes.string,
    documentType: PropTypes.string,
    identVideo: PropTypes.string,
    rawDataIdNow: PropTypes.string,
    rawDataSSU: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    files: PropTypes.any,
    // eslint-disable-next-line react/forbid-prop-types
    currentState: PropTypes.any,
    personMatchError: PropTypes.shape({
      did: PropTypes.string,
      personMatch: PropTypes.shape({}),
      stacktrace: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          isMatching: PropTypes.bool,
          compared: PropTypes.shape({
            valueA: PropTypes.string,
            valueB: PropTypes.string,
          }),
        })
      ),
    }),

    dataSource: PropTypes.string,
    identData: PropTypes.oneOfType([PropTypes.object]),
  }),
};
