import { makeStyles } from '@material-ui/core/styles';

export const EditRequestStatusStyle = makeStyles({
  button: {
    color: 'rgb(0, 158, 226)',
  },
  textInput: {
    width: '25%',
  },
  textInputLabel: {
    color: '#B5B5B5',
  },
  textFieldLabel: {
    color: 'green',
  },
  textField: {
    color: '#4C4C4C',
  },
  horizontalLine: {
    borderTop: '1px solid grey',
  },
  span: {
    color: '#0000008a',
  },
});
