import React from 'react';

import { List, Datagrid, TextField } from 'react-admin';

import { LinkField } from '../link/LinkField';

export const VerifierList = (props) => (
  <List
    {...props}
    bulkActionButtons={false}
    sort={{ field: 'name', order: 'ASC' }}
    title='Verifier'
  >
    <Datagrid>
      <TextField source='verifierId' label='Verifier-ID' textAlign='left' />
      <TextField source='apiKey' label='API-KEY' textAlign='left' />
      <LinkField
        filterField='verifier'
        filterValue='name'
        nameFields={['name']}
        pathname='orders'
        label='Name'
      />
    </Datagrid>
  </List>
);
