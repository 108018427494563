import React, { Fragment, useState, useEffect } from 'react';

import { Chip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DesktopAccessDisabledIcon from '@material-ui/icons/DesktopAccessDisabled';
import TimerIcon from '@material-ui/icons/Timer';
import TimerOffIcon from '@material-ui/icons/TimerOff';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { CrefoChip } from '../crefo-chip/CrefoChip';
import { CustomArrayChipFieldStyle } from './CustomArrayChipField.style';

export const CustomArrayChipField = ({ record, source, limit, type }) => {
  const classes = CustomArrayChipFieldStyle();
  let shadowData = record[source];
  const filterNeeded = shadowData?.length > limit;
  const filterAmount = shadowData?.length - limit;
  let showMore = 'Mehr anzeigen';
  const showLess = 'Weniger anzeigen';

  if (type === 'persons') {
    shadowData = shadowData?.sort((a, b) => b.createdAt - a.createdAt);
    showMore = `+${filterAmount} anzeigen`;
  }

  const [data, setData] = useState(shadowData);

  const [filtered, setFiltered] = useState(true);
  const [filteredText, setFilteredText] = useState(showMore);

  const toggleFilter = () => {
    if (filtered) {
      setFilteredText(showLess);
      setData(shadowData);
      setFiltered(false);
    } else {
      setFilteredText(showMore);
      setData(shadowData.slice(0, limit));
      setFiltered(true);
    }
  };

  useEffect(() => {
    if (filterNeeded && showMore && shadowData && limit) {
      setFilteredText(showMore);
      setData(shadowData.slice(0, limit));
    }
  }, [filterNeeded, showMore, shadowData, limit]);

  if (shadowData === undefined) return <></>;
  if (type === 'company') {
    return (
      <>
        {data.map((el) => {
          const familyName = el?.familyName;
          const givenName = el?.givenName;
          let name = '';
          if (givenName && familyName) {
            name = `${givenName} ${familyName}`;
          }
          return (
            <div key={uuidv4()}>
              <CrefoChip label={name} />
            </div>
          );
        })}
        {filterNeeded ? (
          <div>
            <br />
            <Button onClick={toggleFilter}>{filteredText}</Button>
          </div>
        ) : null}
      </>
    );
  }
  return (
    <>
      {data.map((el) => {
        let style = classes.invalidChip;
        let icon = <TimerOffIcon />;
        if (el.status === 'VALID') {
          style = classes.validChip;
          icon = <TimerIcon />;
        } else if (el.status === 'REVOKED') {
          icon = <DesktopAccessDisabledIcon />;
        }
        return (
          <div id={el.id} key={el.id}>
            <Chip className={style} icon={icon} label={el.type} />
          </div>
        );
      })}
      {filterNeeded ? (
        <div>
          <br />
          <Button onClick={toggleFilter}>{filteredText}</Button>
        </div>
      ) : null}
    </>
  );
};

CustomArrayChipField.defaultProps = {
  record: {},
  source: '',
  limit: 0,
  type: '',
};

CustomArrayChipField.propTypes = {
  record: PropTypes.shape({}),
  source: PropTypes.string,
  limit: PropTypes.number,
  type: PropTypes.string,
};
