import * as React from 'react';

import PropTypes from 'prop-types';

export const LatestOrderStep = ({ record, source }) => {
  const valueFormatter = (orderState) => orderState?.payload?.comment || orderState?.message || orderState.comment || JSON.stringify(orderState.payload, null, 2);

  const data = record?.[source];
  const latest = data?.[data?.length - 1];

  return <p>{latest ? valueFormatter(latest) : 'nA'}</p>;
};

LatestOrderStep.defaultProps = {
  record: {
    states: [],
  },
  source: '',
};

LatestOrderStep.propTypes = {
  record: PropTypes.shape({}),
  source: PropTypes.string,
};
