import React from 'react';

export const RejectIcon = () => (
  <div style={{ margin: '3px 7px 0px 0px' }}>
    <svg
      width='15'
      height='15'
      viewBox='0 0 15 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.7379 12.2379C11.5547 12.4211 11.2584 12.4211 11.075 12.2379L7.18814 8.35019L3.30044 12.2379C3.11725 12.4211 2.82085 12.4211 2.63746 12.2379C2.45426 12.0547 2.45426 11.7583 2.63746 11.5749L6.52603 7.68808L2.63804 3.80038C2.45485 3.61719 2.45485 3.32079 2.63804 3.13739C2.82124 2.9542 3.11763 2.9542 3.30103 3.13739L7.18814 7.02597L11.0758 3.13827C11.259 2.95508 11.5554 2.95508 11.7388 3.13827C11.922 3.32147 11.922 3.61786 11.7388 3.80126L7.85025 7.68808L11.7379 11.5758C11.9225 11.7574 11.9225 12.0562 11.7379 12.2379Z'
        fill='#009EE2'
        stroke='#009EE2'
        strokeWidth='0.8'
      />
    </svg>
  </div>
);
