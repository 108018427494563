import React, { useState } from 'react';

import {
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  useDataProvider,
  useNotify,
} from 'react-admin';

export const UseCaseCreate = (props) => {
  const dataProvider = useDataProvider(); // Used for calling the data provider directly
  const notify = useNotify();
  const [requiredCredentials] = useState({
    person: ['crid', 'crefoId', 'name', 'address', 'nationality', 'birthdate'],
    org: ['legalName', 'legalForm', 'crefoId', 'address'],
    credential: ['authorizedRepresentative', 'companyAgent'],
  });
  const [relatedServices] = useState([
    'ubo',
    'myesg',
    'mein-firmeneintrag',
    'meine-bonitaet',
  ]);
  const [offeredIdMethods] = useState([
    { id: 'itm_videoident', label: 'ITM VideoIdent' },
    { id: 'nect_autoident', label: 'NECT Autoident' },
    { id: 'pinletter_ident', label: 'Pin Letter Ident' },
    { id: 'pin_delegation', label: 'Delegation Pin Order Letter' },
    { id: 'authrep_delegation', label: 'Authrep Delegation' },
    {
      id: 'pin_delegation_personal',
      label: 'Delegation Pin Order Letter für Vertretungsberechtigten',
    },
  ]);

  const handleSave = (data) => {
    const request = data;
    request.requiredCredentials.person = requiredCredentials.person
      .filter((method) => data.requiredCredentials.person[method])
      .map(String);
    request.requiredCredentials.org = requiredCredentials.org
      .filter((method) => data.requiredCredentials.org[method])
      .map(String);

    request.requiredCredentials.credential = requiredCredentials.credential
      .filter((method) => data.requiredCredentials.credential[method])
      .map(String);

    request.relatedServices = relatedServices
      .filter((method) => data.relatedServices[method])
      .map(String);

    request.offeredIdMethods = offeredIdMethods
      .filter((method) => data.offeredIdMethods[method.id])
      .map((method) => method.id);
    // Accecpted and Offered Id Methods must be the same
    request.acceptedIdMethods = request.offeredIdMethods;

    // Custom save logic using the dataProvider
    dataProvider
      .create('useCases', { data: request })
      .then(() => {
        notify('Record created successfully', 'info');
      })
      .catch((error) => {
        notify(`Error: ${error.message}`, 'warning');
      });
  };

  return (
    <Create {...props}>
      <SimpleForm save={handleSave}>
          <TextInput source='name' label='Name' />
          <TextInput source='verifierId' label='VerifierId' />
          <TextInput source='redirect.success' label='Success Url' />
          <TextInput source='redirect.failure' label='Failure Url' />
          <TextInput source='redirect.origin' label='Origin Url' />
          <TextInput source='webhook' label='Webhook Url' />
          <TextInput source='entryUrl' label='Entry Url' />
          <TextInput source='content.name' label='Content Name' />
          <TextInput source='content.title' label='Content Titel' />
          <TextInput
            source='content.description'
            label='Content Beschreibung'
          />
          <TextInput source='content.logoUrl' label='Content LogoUrl' />
          <TextInput source='serviceUrl' label='Service Url' />
          <TextInput source='lang' label='Sprache' defaultValue='de' />
        <h3>Angebotene Ident Methoden:</h3>
        {offeredIdMethods && (
          <>
            {offeredIdMethods.map((field) => (
              <BooleanInput
                key={field.id}
                label={field.label}
                source={`offeredIdMethods.${field.id}`}
                defaultValue={false}
              />
            ))}
          </>
        )}

        <h3>Required Credentials:</h3>
        {requiredCredentials.person && (
          <>
            <h4>Person:</h4>
            {requiredCredentials.person.map((field) => (
              <BooleanInput
                key={field}
                label={field}
                source={`requiredCredentials.person.${field}`}
                defaultValue={false}
              />
            ))}
          </>
        )}

        <h4>Organization (org):</h4>
        {requiredCredentials.org && (
          <>
            <h4>Organisation:</h4>
            {requiredCredentials.org.map((field) => (
              <BooleanInput
                key={field}
                label={field}
                source={`requiredCredentials.org.${field}`}
                defaultValue={false}
              />
            ))}
          </>
        )}

        <h4>Credentials:</h4>
        {requiredCredentials.credential && (
          <>
            {requiredCredentials.credential.map((field) => (
              <BooleanInput
                key={field}
                label={field}
                source={`requiredCredentials.credential.${field}`}
                defaultValue={false}
              />
            ))}
          </>
        )}

        <h3>Verwandte Services</h3>
        {relatedServices && (
          <>
            {relatedServices.map((field) => (
              <BooleanInput
                key={field}
                label={field}
                source={`relatedServices.${field}`}
                defaultValue={false}
              />
            ))}
          </>
        )}
      </SimpleForm>
    </Create>
  );
};
