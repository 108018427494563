import React from 'react';

import { Filter, SelectInput, TextInput, DateInput } from 'react-admin';

export const OrdersFilter = (props) => (
  <Filter {...props}>
    <TextInput label='Order-ID' source='orderId' />
    <TextInput label='CRID' source='crid' />
    <TextInput label='Verifier' source='verifierName' />
    <SelectInput
      label='Status'
      source='comment'
      choices={[
        { id: 0, name: 'order created' },
        { id: 1, name: 'account created' },
        { id: 2, name: 'logged in' },
        { id: 100, name: 'selected company' },
        { id: 3, name: 'request created' },
        { id: 4, name: 'person verified' },
        {
          id: 5,
          name: 'person is authorized representative',
        },
        { id: 7, name: 'person is company agent' },
        { id: 6, name: 'data sent' },
        { id: 102, name: 'delivery error' },
        { id: 101, name: 'timed out' },
        { id: 103, name: 'process canceled' },
        { id: 104, name: 'requests rejected' },
        { id: 123, name: 'order completed' },
      ]}
    />
    <TextInput label='Use Case' source='useCase' />
    <TextInput label='Unternehmens-Crefo-Nr.' source='crefoId' />
    <DateInput label='Von' source='createdAt' alwaysOn />
    <DateInput label='Bis' source='toValueOfPeriodTimeFilter' alwaysOn />
  </Filter>
);
