import React from 'react';

import { Filter, TextInput, DateInput } from 'react-admin';

export const CompanyFilter = (props) => (
  <Filter {...props}>
    <TextInput label='Crefonummer' source='crefoId' />
    <TextInput label='Firmenname' source='legalName' />
    <TextInput label='Personen-CRID' source='crid' />
    <DateInput label='Von' source='createdAt' alwaysOn />
    <DateInput label='Bis' source='toValueOfPeriodTimeFilter' alwaysOn />
  </Filter>
);
