import React, { useState, useEffect } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import FileSaver from 'file-saver';
import {
  SimpleForm,
  TextInput,
  FileField,
  FileInput,
  useNotify,
  SelectInput,
  Button,
  required,
} from 'react-admin';

import { DescriptionGen } from './DescriptionGen';

const useStyles = makeStyles({
  fileInput: {
    margin: '20px 0',
    borderRadius: '5px',
    padding: '10px',
    backgroundColor: '#f0f0f0',
  },
  fileField: {
    display: 'grid',
    alignItems: 'center',
    padding: '5px',
    color: '#1976d2',
  },
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
});

export const PinGeneration = () => {
  const [useCase, setUseCase] = useState('');
  const [verifierId, setVerifierId] = useState('');
  const [crefoTrustKey, setCrefoTrustKey] = useState('');
  const [verifierSearchName, setverifierSearchName] = useState('');
  const [verifierName, setverifierName] = useState('');
  const [verifierOptions, setVerifierOptions] = useState(null);
  const [options, setOptions] = useState([]);
  const [file, setFile] = useState(null);
  const [useCaseLoading, setUseCaseLoading] = useState(false);
  const [isVerifierLoading, setIsVerifierLoading] = useState(false);
  const notify = useNotify();
  const classes = useStyles();

  const handleUpload = async () => {
    if (!file) {
      notify('File is required', { type: 'error' });
      return;
    }
    const formData = new FormData();
    formData.append('file', file);
    formData.append('crefoTrustKey', crefoTrustKey);
    formData.append('verifierId', verifierId);
    formData.append('useCaseName', useCase);
    await fetch(`${window._env_.API_URL}/pins`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'x-crefotrust-api-key': crefoTrustKey,
      },
    })
      .then(async (res) => {
        FileSaver.saveAs(await res.blob(), file.name);
      })
      .catch(() => {
        notify('No Pins generated found.', { type: 'error' });
      });
  };

  const handleVerifierChange = async () => {
    try {
      setIsVerifierLoading(true);
      await fetch(
        `${window._env_.API_URL}/verifiers/${verifierSearchName.target.value}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'x-crefotrust-api-key': crefoTrustKey,
          },
        }
      )
        .then(async (res) => {
          res.json().then((json) => {
            setVerifierOptions(
              json.map((option) => ({
                id: option.name,
                name: option.name,
              }))
            );
          });
        })
        .catch(() => {
          notify('No verifier found.', { type: 'warning' });
        })
        .finally(() => {setIsVerifierLoading(false);});
    } catch (error) {
      notify('No verifier found.', { type: 'warning' });
    }
  };

  const resetAll = () => {
    setUseCase('');
    setVerifierId('');
    setOptions([]);
    setverifierSearchName('');
    setverifierName('');
    setVerifierOptions(null);
    setCrefoTrustKey('');
  };

  useEffect(() => {
    const fetchOptions = async () => {
      if (verifierId) {
        await fetch(`${window._env_.API_URL}/verifiers/useCase/${verifierId}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'x-crefotrust-api-key': crefoTrustKey,
          },
        })
          .then((response) => response.json()
          .then((data) => {
            setOptions(
              data.useCases.map((option) => ({
                id: option.name,
                name: option.name,
              }))
            );
          })).catch(() => {
            notify('No use case found.', { type: 'warning' });
          });
      } else if (verifierName) {
        setUseCaseLoading(true);
        await fetch(`${window._env_.API_URL}/verifiers/${verifierName}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'x-crefotrust-api-key': crefoTrustKey,
          },
        })
          .then(async (res) => {
            res.json().then((json) => {
              setVerifierId(json[0].verifierId);
              setCrefoTrustKey(json[0].apiKey);
            });
          })
          .finally(() => {
            setUseCaseLoading(false);
          });
      }
    };

    fetchOptions();
  }, [verifierId, verifierName, crefoTrustKey, useCaseLoading, notify]);

  return (
    <SimpleForm save={handleUpload} filter={[]}>
      <FileInput
        source='file'
        label='File Upload'
        accept='.csv'
        onChange={(e) => setFile(e)}
        placeholder={<p>Drop your CSV file here</p>}
        className={classes.fileInput}
        validate={required()}>
        <FileField source='src' title='title' className={classes.fileField} />
      </FileInput>
      <TextInput
        label='Finde einen Verifier'
        source='verifierName'
        onChange={(e) => setverifierSearchName(e)}
        // onChange={debouncedChangeHandler}
        variant='outlined'
        validate={required()}
      />
      <Button
        label='Verifier finden'
        onClick={handleVerifierChange}
        className={classes.fileInput}
      />
      {verifierOptions && (
        <SelectInput
          source='verifierName'
          label='Such eine der Verifier aus'
          choices={verifierOptions}
          onChange={(e) => setverifierName(e.target.value)}
          variant='outlined'
          validate={required()}
        />
      )}
      {isVerifierLoading && (
        <div className={classes.spinnerContainer}>
          <CircularProgress />
        </div>
      )}
      {verifierOptions && verifierId && (
        <SelectInput
          source='useCase'
          label='Such einen Use case aus'
          choices={options}
          onChange={(e) => setUseCase(e.target.value)}
          variant='outlined'
          validate={required()}
        />
      )}
      {verifierOptions && useCaseLoading && (
        <div className={classes.spinnerContainer}>
          <CircularProgress />
        </div>
      )}
      <DescriptionGen />
      <Button
        label='Reset'
        onClick={resetAll}
        className={classes.fileInput}
      />
    </SimpleForm>
  );
};
