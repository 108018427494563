import React from 'react';

import PropTypes from 'prop-types';
import { TopToolbar } from 'react-admin';

/**
 * const COMPANY_IDENT_COUNT_LABEL = 'Anzahl ident. Unternehmen';
 * const COMPANY_TOTAL_COUNT_LABEL = 'Anzahl Unternehmen';
 * */

export const CustomActionBar = ({
  resource,
  filters,
  displayedFilters,
  filterValues,
  showFilter,
}) => 

    /**
     * const IdentQuery = () =>
     *     useQuery({
     *       type: 'getList',
     *       resource: 'companies/count',
     *       payload: {
     *         pagination: { page: 1, perPage: 0 },
     *         sort: { field: '_id', order: 'ASC' },
     *         filter: '{"crefoId":{"$not":{"$eq":null}}}',
     *       },
     *     });
     *
     *   const TotalQuery = () =>
     *     useQuery({
     *       type: 'getList',
     *       resource: 'companies/count',
     *       payload: {
     *         pagination: { page: 1, perPage: 0 },
     *         sort: { field: '_id', order: 'ASC' },
     *         filter: {},
     *       },
     *     });
     *
     * { (data && !error && !loading) && <>
     *             <QueryBadge
     *                 queryFunction={IdentQuery}
     *                 label={COMPANY_IDENT_COUNT_LABEL}
     *             />
     *             <QueryBadge
     *                 queryFunction={TotalQuery}
     *                 label={COMPANY_TOTAL_COUNT_LABEL}
     *             />
     *         </>}
     *         const { data, error, loading } = TotalQuery();
     * */

   (
    <TopToolbar>
      {' '}
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}

    </TopToolbar>
  )
;

CustomActionBar.defaultProps = {
  resource: '',
};

CustomActionBar.propTypes = {
  resource: PropTypes.string,
  filters: PropTypes.shape({}).isRequired,
  displayedFilters: PropTypes.shape({}).isRequired,
  filterValues: PropTypes.shape({}).isRequired,
  showFilter: PropTypes.func.isRequired,
};
