import { stringify } from 'query-string';
import { fetchUtils } from 'react-admin';
import { v4 as uuidv4 } from 'uuid';

import { RequestStatus } from './components/edit-request-status/RequestStatus.const';

// custom dataProvider based on https://github.com/marmelab/react-admin/blob/master/packages/ra-data-simple-rest/src/index.ts
export const CrefoDataProvider = (apiUrl, httpClient) => ({
  getList: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    let filter = JSON.stringify(fetchUtils.flattenObject(params.filter));
    if (filter.includes('$')) {
      filter = params.filter;
    }

    const query = {
      sort: field,
      order,
      start: (page - 1) * perPage,
      end: page === 1 ? perPage : perPage * page,
    };
    let url = `${apiUrl}/${resource}?${stringify(query)}`;

    if (Object.keys(params.filter).length !== 0) {
      url += `&filter=${filter}`;
    }
    return httpClient(url).then(({ headers, json }) => {
      if (!headers.has('Content-Range')) {
        throw new Error(
          'The Content-Range header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?'
        );
      }
      const total = parseInt(headers.get('Content-Range').split('/').pop(), 10);
      return {
        data: json.map((record) => ({
          ...record,
          objectId: record.id,
          id: record.id ?? record._id ?? uuidv4(),
        })),
        total,
      };
    });
  },

  // React Admin calls getOne() after updating one entry. As the record is removed from the visible data after updating, this results in the same behaviour like deletion.
  // As the data is not present anymore, we see a warning message 'Element does not exist'
  // https://github.com/marmelab/react-admin/issues/5541
  getOne: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => {
      if (json) {
        return { data: { ...json, id: json._id } };
      }
      return { data: { id: json.id } };
    }),

  download: (resource, params) =>
    httpClient(`${apiUrl}/requests/asdf/files/${params.id}`),

  getMany: async (resource, params) => {
    const url = `${apiUrl}/${resource}`;
    const response = await httpClient(url);
    const data = JSON.parse(response.body);
    const filtered = data.resources.filter((el) => params.ids.includes(el.did));
    return { data: filtered, total: filtered.length };
    // return httpClient(url).then(({ json }) => ({ data: json }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => {
      if (!headers.has('content-range')) {
        throw new Error(
          'The Content-Range header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?'
        );
      }
      return {
        data: json,
        total: parseInt(headers.get('content-range').split('/').pop(), 10),
      };
    });
  },

  update: async (resource, params) => {
    const hasData = params?.data?.update ?? false;
    if (!hasData) {
      return { data: { id: params._id } };
    }

    if (resource === 'requests') {
      let url = '';

      switch (params.data.update.state) {
        case RequestStatus.ACCEPTED: {
          url = `${apiUrl}/requests/${params.id}/accept`;
          break;
        }
        case RequestStatus.REJECTED: {
          url = `${apiUrl}/requests/${params.id}/reject`;
          break;
        }
        case RequestStatus.REVOKED: {
          url = `${apiUrl}/requests/${params.id}/revoke-credentials`;
          break;
        }
        default:
          return { data: {} };
      }
      const response = await httpClient(url, {
        method: 'POST',
        body: JSON.stringify(params.data.update),
      });
      if (response.status === 204) {
        return { data: { id: params.id } };
      }
    }
    return { data: { id: params._id } };
  },

  // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
  // TODO: delete functions if not need by react admin data provider
  updateMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}?id=${id}`, {
          method: 'PATCH',
          body: JSON.stringify(params.data),
        })
      )
    )
      .then(() => ({ data: [] }))
      .catch((err) => console.log(err)),

  create: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    })),

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json })),

  // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
  deleteMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'DELETE',
        })
      )
    ).then((responses) => ({ data: responses.map(({ json }) => json.id) })),
});
