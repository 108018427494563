import React from 'react';

import PropTypes from 'prop-types';
import { stringify } from 'query-string';
import { Link, useRecordContext } from 'react-admin';

export const LinkField = (props) => {
  const { filterField, filterValue, nameFields, pathname } = props;
  const record = useRecordContext(props);
  const data = record[filterValue] ? record[filterValue] : undefined;
  if (!data) {
    return <></>;
  }
  return (
    <div>
      <Link
        to={{
          pathname,
          search: stringify({
            filter: JSON.stringify({
              [filterField]: record[filterValue],
            }),
          }),
        }}
      >
        <span style={{ whiteSpace: 'nowrap' }}>
          {nameFields && nameFields.map((field) => `${record[field]} `)}
        </span>
      </Link>
      <br />
    </div>
  );
};

LinkField.defaultProps = {
  filterField: '',
  filterValue: '',
  nameFields: [],
  pathname: '',
};

LinkField.propTypes = {
  filterField: PropTypes.string,
  filterValue: PropTypes.string,
  nameFields: PropTypes.arrayOf(PropTypes.string),
  pathname: PropTypes.string,
};
