import * as React from 'react';

import { DataGrid } from '@material-ui/data-grid';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

export const OrderStepTable = ({ record, source }) => {
  const data = record?.[source];

  const valueFormatter = (params) => params.row?.payload?.comment || params?.row?.payload?.error || params.row?.message;

  const valueGetter = (params) => new Date(params?.row?.createdAt).toLocaleString('de')

  const columns = [
    {
      field: 'message',
      headerName: 'System',
      flex: 1,
      valueFormatter: (params) => `${params?.row?.code} ${params.row?.message ?` - ${  params.row?.message}` : ''}`,
    },
    {
      field: 'comment',
      headerName: 'Kommentar',
      flex: 2,
      valueFormatter,
    },
    { field: 'timestamp', headerName: 'Datum', flex: 0.5, valueGetter },
  ];

  // DataGrid table requires an id
  const rows = data ? data?.map((item) => ({ ...item, id: uuidv4() })) : [];

  return (
    <div style={{ height: 400, width: 'auto' }}>
      <DataGrid rows={rows} columns={columns} pageSize={100} />
    </div>
  );
};

OrderStepTable.defaultProps = {
  record: [
    {
      states: [],
    },
  ],
  source: '',
};

OrderStepTable.propTypes = {
  record: PropTypes.arrayOf(PropTypes.shape),
  source: PropTypes.string,
};
