import React from 'react';

import PropTypes from 'prop-types';
import {v4 as uuidv4} from "uuid";

export const SSUAddressRenderer = ({ record }) => {
  const payload = record.address.ssu;
  if (!payload) {
    return <></>;
  }
  return (
    <>
      {Object.keys(payload).map((key) => (
        <span key={uuidv4()}>
          {key}: {payload[key]}
          <br />
        </span>
      ))}
    </>
  );
};

SSUAddressRenderer.defaultProps = {
  record: {
    address: {
      ssu: {},
    },
  },
};

SSUAddressRenderer.propTypes = {
  record: PropTypes.shape({
    address: PropTypes.shape({ ssu: PropTypes.shape({}) }),
  }),
};
