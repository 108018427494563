import React from 'react';

import PropTypes from 'prop-types';
import { stringify } from 'query-string';
import { Link, useRecordContext } from 'react-admin';
import { v4 as uuidv4 } from 'uuid';

export const NestedLinkField = (props) => {
  const { filterField, parentFilterValue, filterValue, pathname } = props;
  const record = useRecordContext(props);

  if (record?.[parentFilterValue]?.[filterValue]) {
    return (
      <div>
        <span key={uuidv4()}>
          <Link
            to={{
              pathname,
              search: stringify({
                filter: JSON.stringify({
                  [filterField]: record[parentFilterValue][filterValue],
                }),
              }),
            }}>
            <span style={{ whiteSpace: 'nowrap' }}>
              {record[parentFilterValue][filterValue]}
            </span>
          </Link>
          <br />
        </span>
      </div>
    );
  }
  if (record?.[filterValue]) {
    return (
      <div>
        <span key={uuidv4()}>
          <Link
            to={{
              pathname,
              search: stringify({
                filter: JSON.stringify({
                  [filterField]: record[filterValue],
                }),
              }),
            }}>
            {record[filterValue]}
          </Link>
          <br />
        </span>
      </div>
    );
  }

  return null;
};

NestedLinkField.defaultProps = {
  filterField: '',
  parentFilterValue: '',
  filterValue: '',
  pathname: '',
};

NestedLinkField.propTypes = {
  filterField: PropTypes.string,
  parentFilterValue: PropTypes.string,
  filterValue: PropTypes.string,
  pathname: PropTypes.string,
};
