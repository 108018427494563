import { CrefoTrusteeManager } from './manager/CrefoTrusteeManager';

const crefoTrusteeManager = new CrefoTrusteeManager({
  /* eslint no-underscore-dangle: 0 */
  apiUrl: window._env_.API_URL,
  token: localStorage.getItem('jwt'),
});

export const CrefoAuthProvider = {
  login: async (credentials) => {
    const response = await crefoTrusteeManager.login(
      credentials.username,
      credentials.password
    );
    localStorage.setItem('isAuthenticated', true);
    localStorage.setItem('email', credentials.username);
    localStorage.setItem('token', response.token);
  },
  logout: async () => {
    localStorage.clear();
  },
  checkAuth: () =>
    localStorage.getItem('token') ? Promise.resolve() : Promise.reject(),
  checkError: (error) => {
    const { status } = error;
    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: () => Promise.resolve(),
};
