import React from 'react';

import PropTypes from 'prop-types';
import { stringify } from 'query-string';
import { Link, useRecordContext } from 'react-admin';
import { v4 as uuidv4 } from 'uuid';

export const ObjectsLinkField = (props) => {
  const { entryField, filterField, filterValue, nameFields, pathname } = props;
  const record = useRecordContext(props);
  const entries = record[entryField]?.filter((el) => el[filterValue]);

  return (
    <div>
      {entries &&
        entries.map((entry) => (
          <span key={uuidv4()}>
            <Link
              to={{
                pathname,
                search: stringify({
                  filter: JSON.stringify({
                    [filterField]: entry[filterValue],
                  }),
                }),
              }}
            >
              <span style={{ whiteSpace: 'nowrap' }}>
                {nameFields && nameFields.filter(field => !!entry[field]).map((field, index) => `${(index > 0 ? ' | ' : '' ) + entry[field]}`)}
              </span>
            </Link>
            <br />
          </span>
        ))}
    </div>
  );
};

ObjectsLinkField.defaultProps = {
  entryField: '',
  filterField: '',
  filterValue: '',
  nameFields: [],
  pathname: '',
};

ObjectsLinkField.propTypes = {
  entryField: PropTypes.string,
  filterField: PropTypes.string,
  filterValue: PropTypes.string,
  nameFields: PropTypes.arrayOf(PropTypes.string),
  pathname: PropTypes.string,
};
