import React from 'react';

import PropTypes from 'prop-types';
import { List, Datagrid, TextField } from 'react-admin';

import { IdentAddressRenderer } from './IdentAddressRenderer';
import { PostFilter } from './PostFilter';
import { SSUAddressRenderer } from './SSUAddressRenderer';
import { DateFieldForTimestamp } from '../date-field-for-timestamp/DateFieldForTimestamp';
import { EditRequestStatus } from '../edit-request-status/EditRequestStatus';
import { LinkField } from '../link/LinkField';

const NameField = ({ record }) => {
  const givenName = record?.givenName;
  const familyName = record?.familyName;
  const name = givenName && familyName ? `${givenName} ${familyName}` : '';

  return <span>{name}</span>;
};

export const RequestList = (props) => (
  <List
    {...props}
    filters={<PostFilter />}
    title='Anfragen Vertretungsberechtigung'
    bulkActionButtons={false}
    sort={{ field: 'createdAt', order: 'DESC' }}>
    <Datagrid expand={<EditRequestStatus />}>
      <LinkField
        filterField='orderId'
        filterValue='orderId'
        nameFields={['orderId']}
        pathname='orders'
        label='Order-ID'
        sortBy='orderId'
      />
      <NameField label='Name' textAlign='left' sortBy='givenName' />
      <LinkField
        filterField='username'
        filterValue='email'
        nameFields={['email']}
        pathname='persons'
        label='Crid'
        sortBy='crid'
      />
      <TextField
        source='crefoId'
        label='Crefonummer'
        textAlign='left'
        sortBy='crefoId'
      />
      <LinkField
        filterField='legalName'
        filterValue='legalName'
        nameFields={['legalName']}
        pathname='companies'
        label='Unternehmen'
        sortBy='legalName'
      />
      <TextField
        source='dataSource'
        label='Ident-Provider'
        textAlign='left'
        sortBy='dataSource'
      />
      <TextField
        source='idCardType'
        label='Dokument'
        textAlign='left'
        sortable={false}
      />
      <TextField
        source='birthdate.ident'
        label='Geburtstag (Ident)'
        textAlign='left'
        sortBy='birthdateIdent'
      />
      <TextField
        source='birthdate.ssu'
        label='Geburtstag (SSU)'
        textAlign='left'
        sortBy='birthdateSsu'
      />
      <IdentAddressRenderer
        label='Adresse (Ident)'
        textAlign='left'
        sortable={false}
      />
      <SSUAddressRenderer
        label='Adresse (SSU)'
        textAlign='left'
        sortable={false}
      />
      <DateFieldForTimestamp
        source='createdAt'
        label='Erstellt'
        showTime
        sortBy='createdAt'
      />
    </Datagrid>
  </List>
);

NameField.defaultProps = {
  record: {
    givenName: '',
    familyName: '',
  },
};

NameField.propTypes = {
  record: PropTypes.shape({
    givenName: PropTypes.string,
    familyName: PropTypes.string,
  }),
};
