import React from 'react';

import PropTypes from 'prop-types';
import {
  useUpdate,
  useRefresh,
  useNotify,
  Toolbar,
  SaveButton,
} from 'react-admin';

import { AcceptIcon } from '../../assets/icons/AcceptIcon';
import { RejectIcon } from '../../assets/icons/RejectIcon';
import { RevokeIcon } from '../../assets/icons/RevokeIcon';
import { EditRequestStatusButtonStyle } from './EditRequestStatusButton.style';
import { RequestStatus } from './RequestStatus.const';

export const EditRequestStatusButton = (props) => {
  const { selectedId, comment, userCrefoId, ...rest } = props;
  const classes = EditRequestStatusButtonStyle();
  const refresh = useRefresh();
  const notify = useNotify();

  const [accept, { loadingAccept }] = useUpdate(
    'requests',
    selectedId,
    {
      update: { comment, userCrefoId, state: RequestStatus.ACCEPTED },
    },
    {},
    {
      onSuccess: () => {
        refresh();
        notify('Credentials wurden erfolgreich ausgestellt.');
      },
      onFailure: () => {
        refresh();
        notify('Das Ausstellen der Credentials ist fehlgeschlagen.');
      },
    }
  );

  const [reject, { loadingReject }] = useUpdate(
    'requests',
    selectedId,
    {
      update: {
        comment,
        state: RequestStatus.REJECTED,
      },
    },
    {},
    {
      onSuccess: () => {
        refresh();
        notify('Request wurde erfolgreich abgelehnt.');
      },
      onFailure: () => {
        refresh();
        notify('Ablehnung fehlgeschlagen!');
      },
    }
  );

  const [revoke, { loadingRevoke }] = useUpdate(
    'requests',
    selectedId,
    { update: { comment, state: RequestStatus.REVOKED } },
    {},
    {
      onSuccess: () => {
        refresh();
        notify('Person wurde erfolgreich zurückgesetzt.');
      },
      onFailure: () => {
        refresh();
        notify('Das Zurücksetzen der Person ist fehlgeschlagen.');
      },
    }
  );

  return (
    <Toolbar {...rest} variant='dense' className={classes.toolbar}>
      <SaveButton
        label='ausstellen'
        type='button'
        variant='text'
        disabled={loadingAccept}
        to='requests'
        icon={<AcceptIcon />}
        className={classes.acceptButton}
        onSuccess={accept}
      />

      <SaveButton
        label='ablehnen'
        type='button'
        variant='text'
        disabled={loadingReject}
        to='requests'
        icon={<RejectIcon />}
        onSuccess={reject}
      />

      <SaveButton
        label='Person Zurücksetzen'
        type='button'
        variant='text'
        disabled={loadingRevoke}
        icon={<RevokeIcon />}
        className={classes.resetButton}
        onSuccess={revoke}
      />
    </Toolbar>
  );
};

EditRequestStatusButton.defaultProps = {
  comment: '',
};

EditRequestStatusButton.propTypes = {
  selectedId: PropTypes.string.isRequired,
  userCrefoId: PropTypes.string.isRequired,
  comment: PropTypes.string,
};
