import React from 'react';

import { List, Datagrid, TextField } from 'react-admin';

import { CompanyFilter } from './CompanyFilter';
import { CustomActionBar } from './CustomActionBar';
import { CustomArrayChipField } from '../custom-array-chip-field/CustomArrayChipField';
import { DateFieldForTimestamp } from '../date-field-for-timestamp/DateFieldForTimestamp';
import { ObjectsLinkField } from '../link/ObjectsLinkField';

export const CompanyList = (props) => (
  <List
    {...props}
    actions={<CustomActionBar />}
    filters={<CompanyFilter />}
    bulkActionButtons={false}
    sort={{ field: 'createdAt', order: 'DESC' }}
    title='Unternehmen'
  >
    <Datagrid>
      <TextField label='Crefonummer' source='company.crefoId' />
      <TextField label='Unternehmen' source='company.legalName' />
      <TextField label='DID' source='did' />
      <CustomArrayChipField
        source='credentials'
        label='Ausgestellte VCs'
        textAlign='left'
        limit={2}
        type='persons'
      />
      <ObjectsLinkField
        entryField='accounts'
        filterField='username'
        filterValue='username'
        nameFields={['username', 'name']}
        pathname='persons'
        label='Verknüpfte Personen'
      />
      <DateFieldForTimestamp source='createdAt' label='Erstellt' showTime />
    </Datagrid>
  </List>
);
