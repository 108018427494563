import { format, parse } from 'date-fns';
import { de } from 'date-fns/locale';

const tryParseDate = (date, dateFormats) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const dateFormat of dateFormats) {
    const parsedDate = parse(date, dateFormat, new Date());

    if (!Number.isNaN(parsedDate.getTime())) {
      return parsedDate;
    }
  }
  return null;
};

export const formatDate = (date) => {
  const formats = ['dd.MM.yyyy', 'MM/dd/yyyy', 'yyyy-MM-dd'];

  let formattedDate;

  if (!date) {
    // eslint-disable-next-line no-return-assign
    return (formattedDate = null);
  }

  const parsedDate = tryParseDate(date, formats);

  if (!parsedDate) {
    formattedDate = null;
  }

  formattedDate = format(parsedDate, 'dd.MM.yyyy', { locale: de });

  return formattedDate;
};
