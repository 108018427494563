import React from 'react';

import { List, Datagrid, TextField, DeleteButton } from 'react-admin';

import { ImpersonatingDetails } from './ImpersonatingDetails';

export const ImpersonatingsList = (props) => (
  <List {...props} bulkActionButtons={false} title='Impersonatings'>
    <Datagrid expand={<ImpersonatingDetails />}>
      <TextField source='id' label='ID' textAlign='left' />
      <TextField
        source='impersonatedCrid'
        label='Übernommener Account'
        textAlign='left'
      />
      <TextField
        source='impersonatingCrid'
        label='Analyst Crid'
        textAlign='left'
      />
      <TextField
        source='impersonatingEmail'
        label='Analyst Email'
        textAlign='left'
      />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
);
