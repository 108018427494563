import React from 'react';

import { List, Datagrid, TextField } from 'react-admin';

import { DateFieldForTimestamp } from '../date-field-for-timestamp/DateFieldForTimestamp';
import { NestedLinkField } from '../link/NestedLinkField';
import { LatestOrderStep } from '../order-steps/LatestOrderStep';
import { OrderDetail } from './OrderDetail';
import { OrderListStyles } from "./OrderList.styles";
import { OrdersFilter } from './OrdersFilter';
import { RequiredCredentials } from './RequiredCredentials';

export const OrderList = (props) => {
  const classes = OrderListStyles();

  return (
      <List
          {...props}
          filters={<OrdersFilter />}
          bulkActionButtons={false}
          sort={{ field: 'createdAt', order: 'DESC' }}
          title='Order'>
        <Datagrid expand={<OrderDetail />} classes={{ rowOdd: classes.rowOdd }}>
          <TextField source='version' label='Version' textAlign='left' />
          <TextField source='id' label='Order-ID' textAlign='left' />
          <TextField
              source='options.verifierName'
              label='Verifier'
              textAlign='left'
              sortBy='verifier'
          />
          <TextField
              source='options.useCase'
              label='Use Case'
              textAlign='left'
              sortBy='useCase'
          />
          <TextField
              source='completed'
              label='Ausgeliefert'
              textAlign='left'
              sortBy='completed'
          />
          <LatestOrderStep
              label='Aktueller Status'
              source='states'
              sortBy='stateComment'
          />
          <TextField
              source='processDuration'
              label='Prozessdauer'
              textAlign='left'
              sortable={false}
          />
          <NestedLinkField
              parentFilterValue='company'
              filterField='crefoId'
              filterValue='crefoId'
              pathname='companies'
              label='Unternehmens-Crefo-Nr.'
              sortBy='company.crefoId'
          />
          <NestedLinkField
              parentFilterValue='account'
              filterField='username'
              filterValue='username'
              pathname='persons'
              label='Verlinkte Crid'
              sortBy='account.username'
          />
          <RequiredCredentials
              source='requiredCredentials'
              label='Bestellte Credentials'
              limit={3}
              sortable={false}
          />
          <DateFieldForTimestamp source='createdAt' label='Erstellt' showTime />
        </Datagrid>
      </List>
  )
};
