import React from 'react';

import { Filter, TextInput, DateInput } from 'react-admin';

export const PersonsFilter = (props) => (
  <Filter {...props}>
    <TextInput label='CRID' source='username' />
    <TextInput label='E-Mail' source='email' />
    <TextInput label='Name' source='familyName' />
    <TextInput label='Vorname' source='givenName' />
    <TextInput
      label='Status'
      source='verified'
      parse={(data) => data.toUpperCase()}
    />
    <TextInput label='Crefonummer' source='crefoId' />
    <DateInput label='Von' source='createdAt' alwaysOn />
    <DateInput label='Bis' source='toValueOfPeriodTimeFilter' alwaysOn />
  </Filter>
);
