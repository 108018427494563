import React from 'react';

export const AcceptIcon = () => (
  <div style={{ margin: '3px 7px 0px 0px' }}>
    <svg
      width='15'
      height='15'
      viewBox='0 0 15 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.6123 3.76255C13.7969 3.94419 13.7969 4.24302 13.6123 4.42466L5.64355 12.3934C5.46191 12.578 5.16309 12.578 4.98145 12.3934L0.762285 8.17466C0.579238 7.99302 0.579238 7.69419 0.762285 7.51255C0.945215 7.32798 1.24199 7.32798 1.4251 7.51255L5.3125 11.4002L12.9502 3.76255C13.1318 3.57915 13.4307 3.57915 13.6123 3.76255Z'
        fill='#009EE2'
        stroke='#009EE2'
        strokeWidth='0.8'
      />
    </svg>
  </div>
);
