import React from 'react';

import { List, Datagrid, TextField } from 'react-admin';

import { DateFieldForTimestamp } from '../date-field-for-timestamp/DateFieldForTimestamp';
import { UseCaseDetail } from './UseCaseDetail';
import { UseCaseStyles } from './UseCaseList.style';

// import { NestedLinkField } from '../link/NestedLinkField';
// import { LatestOrderStep } from '../order-steps/LatestOrderStep';

export const UseCaseList = (props) => {
  const classes = UseCaseStyles();

  return (
    <List
      {...props}
      //   filters={<OrdersFilter />}
      bulkActionButtons={false}
      sort={{ field: 'createdAt', order: 'DESC' }}
      title='Use Cases'>
      <Datagrid expand={<UseCaseDetail />} classes={{ rowOdd: classes.rowOdd }}>
        {/* <TextField source='version' label='Version' textAlign='left' /> */}
        <TextField source='id' label='UseCase-ID' textAlign='left' />
        <TextField source='name' label='Name' textAlign='left' sortBy='name' />
        <TextField
          source='lang'
          label='Sprache'
          textAlign='left'
          sortBy='lang'
        />
        <TextField
          source='verifierId'
          label='Verifier Id'
          textAlign='left'
          sortBy='verifierId'
        />
        {/* <LatestOrderStep
          label='Aktueller Status'
          source='states'
          sortBy='stateComment'
        />
        <TextField
          source='processDuration'
          label='Prozessdauer'
          textAlign='left'
          sortable={false}
        />
        <NestedLinkField
          parentFilterValue='company'
          filterField='crefoId'
          filterValue='crefoId'
          pathname='companies'
          label='Unternehmens-Crefo-Nr.'
          sortBy='company.crefoId'
        />
        <NestedLinkField
          parentFilterValue='account'
          filterField='username'
          filterValue='username'
          pathname='persons'
          label='Verlinkte Crid'
          sortBy='account.username'
        /> */}
        <DateFieldForTimestamp source='createdAt' label='Erstellt' showTime />
      </Datagrid>
    </List>
  );
};
