import React from 'react';

import { Create, SimpleForm, TextField, TextInput } from 'react-admin';

export const ImpersonatingsCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextField label='SSO-Account des zu analysierenden Accounts' />
      <TextInput source='impersonatedCrid' label='Crid' textAlign='left' />
      <TextField label='SSO-Account des Analysten' />
      <TextInput source='impersonatingCrid' label='Crid' textAlign='left' />
      <TextInput source='impersonatingEmail' label='Email' textAlign='left' />
    </SimpleForm>
  </Create>
);
