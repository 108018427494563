import { createMuiTheme } from '@material-ui/core/styles';

export const crefoTheme = createMuiTheme({
  typography: {
    fontFamily: [
      'Open Sans',
      'Arial',
      'Helvetica',
      'sans-serif', // Für Fließtexte kommt ausschließlich die Schriftart Open Sans Regular zum Einsatz.
    ].join(','),
    fontSize: 15,
    fontWeight: 'normal',
    h1: {
      fontFamily: ['Averta Std', 'Open Sans', 'Arial'],
      fontSize: '2.533rem',
      fontWeight: 600,
      color: 'rgb(0, 158, 226)',
    },
    h2: {
      fontFamily: ['Averta Std', 'Open Sans', 'Arial'],
      fontSize: '1.733rem',
      fontWeight: 600,
      color: 'rgb(0, 72, 132)',
    },
    h3: {
      fontFamily: ['Averta Std', 'Open Sans', 'Arial'],
      fontSize: '1.333rem',
      fontWeight: 600,
      color: 'rgb(0, 72, 132)',
    },
  },
  breakpoints: {
    sm: 768,
    md: 1199,
    lg: 1199,
  },
  palette: {
    text: {
      primary: 'rgb(76, 76, 76)', // Die Standard-Schriftfarbe für Fließtexte ist C-Grau 3 (RGB: 76, 76, 76).
    },
    primary: {
      main: 'rgb(0, 158, 226)',
    },
    secondary: {
      main: '#f44336',
    },
    background: {
      default: 'rgb(255, 255, 255)', // weiss
    },
    error: {
      main: '#FF7B7B',
    },
  },
});
