import React, { useEffect } from 'react';

import Box from '@material-ui/core/Box';
import Prism from 'prismjs';
import { useRecordContext } from 'react-admin';
import '../../prism.css';

export const ShowOrderRequests = () => {
  const record = useRecordContext();

  useEffect(() => {
    Prism.highlightAll();
  }, []);

  return (
    <Box component='h2'>
      <pre>
        <code className='language-json'>
          {JSON.stringify(record.personRequests, null, 4)}
        </code>
      </pre>
    </Box>
  );
};
