import React from 'react';

import PropTypes from 'prop-types';

export const IdentAddressRenderer = ({ record }) => {
  const payload = record.address.ident;
  if (!payload) {
    return <></>;
  }
  return (
    <>
      {Object.keys(payload).map((key) => (
        <span key={payload[key]}>
          {key}: {payload[key]}
          <br />
        </span>
      ))}
    </>
  );
};

IdentAddressRenderer.defaultProps = {
  record: {
    address: {
      ident: {},
    },
  },
};

IdentAddressRenderer.propTypes = {
  record: PropTypes.shape({
    address: PropTypes.shape({ ident: PropTypes.shape({}) }),
  }),
};
