import { makeStyles } from '@material-ui/core/styles';

export const CustomArrayChipFieldStyle = makeStyles({
  validChip: {
    backgroundColor: '#DCF2FC',
    color: '#004884',
    margin: '4px 4px 4px 4px',
  },
  invalidChip: {
    backgroundColor: '#EDECE5',
    color: '#4C4C4C',
    margin: '4px 4px 4px 4px',
  },
});
