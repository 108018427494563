import React from 'react';

import { Chip } from '@material-ui/core';
import PropTypes from 'prop-types';

export const CrefoChip = ({ label }) => (
  <Chip style={{ margin: '4px 4px 4px 4px' }} label={label} />
);

CrefoChip.defaultProps = {
  label: '',
};
CrefoChip.propTypes = {
  label: PropTypes.string,
};
