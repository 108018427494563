import React from 'react';

import Typography from '@material-ui/core/Typography';
import { AppBar } from 'react-admin';

import logo from '../../assets/images/logo/Guardian.svg';
import { CrefoAppBarStyle } from './CrefoAppBar.style';

export const CrefoAppBar = (props) => {
  const classes = CrefoAppBarStyle();
  return (
    <AppBar className={classes.muiAppBar} {...props}>
      <img className={classes.logo} src={logo} alt='Creditreform Logo' />
      <span className={classes.spacer} />
      <Typography
        variant='h2'
        color='inherit'
        position='sticky'
        className={classes.title}
        id='react-admin-title'
        align='center'
      />
      <span className={classes.spacer} />
    </AppBar>
  );
};
