import { makeStyles } from '@material-ui/core/styles';

export const EditRequestStatusButtonStyle = makeStyles({
  toolbar: {
    padding: '0px 16px',
  },
  resetButton: {
    marginLeft: 'auto',
  },
  acceptButton: {
    marginRight: '41px',
  },
});
