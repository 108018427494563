import { makeStyles } from '@material-ui/core/styles';

export const CrefoAppBarStyle = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
  muiAppBar: {
    color: '#009ee2',
    background: 'rgb(255, 255, 255)', // Die Hintergrundfarbe des Headers ist immer weiß (RGB: 255, 255, 255).
    boxShadow: '0 0 0.875rem rgba(0, 0, 0, 0.15)',
    zIndex: '201',
    display: 'block',
    width: '100%',
  },
  logo: {
    width: 135,
    height: 43.54,
  },
});
