import React from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FileSaver from 'file-saver';
import { v4 as uuidv4 } from 'uuid';

import { FileIcon } from '../../assets/icons/FileIcon';

export const FileUploadList = (data) => {
  const { files } = data;
  if (!files && !files?.length) {
    return <></>;
  }

  const download = async (file) => {
    await fetch(`${window._env_.API_URL}/requests/asdf/files/${file.id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then(async (res) => {
      FileSaver.saveAs(await res.blob(), file.name);
    });
  };

  return (
    <Box component='div'>
      <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
        {files?.map((file) => (
          <li
            style={{
              cursor: 'pointer',
              display: 'flex',
              gap: '6px',
              alignItems: 'center',
            }}
            key={uuidv4()}>
            <Button onClick={() => download(file)}>
              <FileIcon /> {file?.name}
            </Button>
          </li>
        ))}
      </ul>
    </Box>
  );
};
