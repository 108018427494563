import React, {useEffect, useState} from 'react';

import Button from "@material-ui/core/Button";
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { CrefoChip } from '../crefo-chip/CrefoChip';

export const RequiredCredentials = ({ record, source, limit }) => {
  const shadowData = record[source];
  let showMore = 'Mehr anzeigen';
  const showLess = 'Weniger anzeigen';
  const allCredentials = [
      ...(shadowData?.org || []),
      ...(shadowData?.person || []),
      ...(shadowData?.authorizedRepresentative ? ['authorizedRepresentative'] : []),
      ...(shadowData?.companyAgent ? ['companyAgent'] : []),
  ];

  const filterNeeded = allCredentials?.length > limit;
  const filterAmount = allCredentials?.length - limit;

  showMore = `+${filterAmount} anzeigen`;

  const [data, setData] = useState(allCredentials);

  const [filtered, setFiltered] = useState(false);
  const [filteredText, setFilteredText] = useState(showMore);

  const toggleFilter = () => {
    setFiltered(!filtered)
  };

  useEffect(() => {
    if (filtered) {
      setFilteredText(showLess);
      setData(allCredentials);
    } else {
      setFilteredText(showMore);
      setData(allCredentials.slice(0, limit));
    }
  }, [filtered, limit, showMore, showLess, JSON.stringify(allCredentials)]);

  if (!allCredentials) return <></>;

  return (
      <>
        {data.map((el) => (
            <div key={uuidv4()}>
              <CrefoChip label={el} />
            </div>
        ))}
        {filterNeeded ? (
            <div>
              <br />
              <Button onClick={toggleFilter}>{filteredText}</Button>
            </div>
        ) : null}
      </>
  );
};

RequiredCredentials.defaultProps = {
  record: {},
  source: '',
  limit: 2,
};
RequiredCredentials.propTypes = {
  record: PropTypes.shape({}),
  source: PropTypes.string,
  limit: PropTypes.number,
};
