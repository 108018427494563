/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from 'react';

import Box from '@material-ui/core/Box';
import Prism from 'prismjs';
import PropTypes from 'prop-types';
import '../../prism.css';

export const ShowIdentData = (identData) => {
  useEffect(() => {
    Prism.highlightAll();
  }, []);

  return (
    <Box component='h2'>
      <pre>
        <code className='language-json'>
          {JSON.stringify(identData?.identData, null, 2)}
        </code>
      </pre>
    </Box>
  );
};

ShowIdentData.defaultProps = {
  record: PropTypes.shape({
    identData: {},
  }),
};

ShowIdentData.propTypes = {
  record: PropTypes.shape({
    identData: PropTypes.shape({}),
  }),
};
