/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

import PropTypes from 'prop-types';
import {
  TextField,
  TextInput,
  SimpleForm,
  Show,
  SimpleShowLayout,
  Toolbar,
  SaveButton,
} from 'react-admin';

const RedirectInvToolbar = ({ orderId }) => (
  <Toolbar>
    <SaveButton
      label='Account übernehmen & analysieren'
      handleSubmitWithRedirect={() => {
        document.location.href = window._env_.INV_URL + orderId;
      }}
    />
  </Toolbar>
);

RedirectInvToolbar.propTypes = {
  orderId: PropTypes.string.isRequired,
};

export const ImpersonatingDetails = (props) => {
  const [orderId, setOrderId] = useState('');

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <SimpleForm toolbar={<RedirectInvToolbar orderId={orderId} />}>
          <TextField
            label='Order-Id zum Eintieg in den Kontext des Benutzers (nur verfügbar für Produktivsystem!)'
            style={{ width: '100%' }}
          />
          <TextInput
            label='00000000-0000-0000-0000-000000000000'
            style={{ width: '100%' }}
            source='empty'
            onChange={(e) => setOrderId(e.target.value)}
          />
        </SimpleForm>
      </SimpleShowLayout>
    </Show>
  );
};
