import React from 'react';

export const RevokeIcon = () => (
  <div style={{ margin: '4px 7px 0px 0px' }}>
    <svg
      width='17'
      height='17'
      viewBox='1.5 2 17 11'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.62069 11.8793C9.68462 11.8793 10.7247 11.5639 11.6093 10.9728C12.4939 10.3817 13.1834 9.54155 13.5905 8.55861C13.9977 7.57567 14.1042 6.49407 13.8966 5.45059C13.6891 4.4071 13.1767 3.4486 12.4244 2.69629C11.6721 1.94398 10.7136 1.43165 9.67014 1.22409C8.62666 1.01653 7.54506 1.12306 6.56212 1.53021C5.57918 1.93735 4.73904 2.62683 4.14796 3.51146C3.55687 4.39608 3.24138 5.43611 3.24138 6.50004V9.27935L1.62759 7.66556L1 8.29314L3.68965 10.9828L6.37931 8.29314L5.75172 7.66556L4.13793 9.27935V6.50004C4.13793 5.61343 4.40084 4.74674 4.89341 4.00955C5.38598 3.27237 6.08609 2.6978 6.90521 2.35851C7.72433 2.01922 8.62566 1.93045 9.49523 2.10342C10.3648 2.27638 11.1636 2.70333 11.7905 3.33025C12.4174 3.95718 12.8443 4.75593 13.0173 5.6255C13.1903 6.49507 13.1015 7.3964 12.7622 8.21552C12.4229 9.03463 11.8484 9.73474 11.1112 10.2273C10.374 10.7199 9.50729 10.9828 8.62069 10.9828V11.8793Z'
        fill='#009EE2'
        stroke='#009EE2'
        strokeWidth='0.8'
        transform='scale(1.2)'
      />
    </svg>
  </div>
);
