import axios from 'axios';
import { decode } from 'jsonwebtoken';

/**
 * The CrefoTrusteeManager class provieds an interface for the http trusteeAPI.
 */
export class CrefoTrusteeManager {
  constructor(options) {
    this.token = options.token;
    this.apiUrl = options.apiUrl;
    this.onAuthErrorCallback = options.onAuthErrorCallback;
    this.axios = axios.create({
      baseURL: options.apiUrl,
      timeout: 1000000,
      headers: {
        Authorization: `Bearer ${options.token}`,
      },
    });
  }

  /**
   * Checks the status of the authentication configuration.
   */
  checkAuthState() {
    if (!this.token) {
      throw Error('Missing authentication token!');
    }
  }

  /**
   * Runs an general http delete request.
   *
   * @param {*} url
   */
  async delete(url) {
    try {
      const response = await this.axios.delete(url, {
        headers: {
          Authorization: `Bearer ${this.token}`,
          'Content-Type': 'application/json',
          accept: '*/*',
        },
      });
      return response;
    } catch (error) {
      if (
        this.onAuthErrorCallback !== undefined &&
        error.response &&
        error.response.status === 401
      )
        this.onAuthErrorCallback(error);
      throw error;
    }
  }

  /**
   * Runs an general http get request.
   *
   * @param {*} url
   */
  async get(url) {
    try {
      const response = await this.axios.get(url, {
        headers: {
          Authorization: `Bearer ${this.token}`,
          'Content-Type': 'application/json',
          accept: '*/*',
        },
      });
      return response;
    } catch (error) {
      if (
        this.onAuthErrorCallback !== undefined &&
        error.response &&
        error.response.status === 401
      )
        this.onAuthErrorCallback(error);
      throw error;
    }
  }

  /**
   * Runs an general http post request.
   *
   * @param {*} url
   * @param {*} data
   */
  async post(url, data) {
    try {
      const response = await this.axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${this.token}`,
          'Content-Type': 'application/json',
          accept: '*/*',
        },
      });
      return response;
    } catch (error) {
      if (
        this.onAuthErrorCallback !== undefined &&
        error.response &&
        error.response.status === 401
      )
        this.onAuthErrorCallback(error);
      throw error;
    }
  }

  /**
   * Returns a jwt token for an array of given credentials.
   *
   * @param {*} credentials
   */
  async getVerifiablePresentation(credentials) {
    const response = await this.post('/presentations', credentials);
    return response.data;
  }

  /**
   * Takes an object of {username: *, password: *} and returns the response from the trusteeAPI.
   *
   * @param {*} data
   */
  async register(username, password) {
    const data = { username, password };
    const response = await this.post('/accounts', data);
    return response;
  }

  /**
   * Performs an login.
   *
   * @return {*} { did, status, statusText, token }
   * @param  {*} username
   * @param  {*} password
   */
  async login(username, password) {
    const response = await this.post('/auth/login', { username, password });
    this.token = response.data.token;
    return {
      did: decode(response.data),
      status: response.status,
      statusText: response.statusText,
      token: this.token,
    };
  }

  /**
   * Searches for a given company name and returns all matches.
   *
   * @param {*} companyName
   */
  async searchCompany(companyName) {
    this.checkAuthState();
    const response = await this.get(
      `/search/company/${encodeURI(companyName)}%2A?numberOfResults=5`
    );
    return response;
  }

  /**
   * Searches for all managers (Vertretungsberechtigter) by a given crefo id of a comapny.
   *
   * @param {*} crefoId
   */
  async searchManager(crefoId) {
    const response = await this.get(`/search/manager/${encodeURI(crefoId)}`);
    return response;
  }

  /**
   * Gets all requests for the current user.
   */
  async getRequests() {
    this.checkAuthState();
    const response = await this.get('/requests');
    return response;
  }

  /**
   * Creates a new request for given payload.
   *
   * @param {*} userData
   */
  async createRequest(data) {
    this.checkAuthState();
    const response = await this.post('/requests', data);
    return response;
  }

  /**
   * Returns a request by a given id.
   *
   * @param {*} id
   */
  async getRequest(id) {
    this.checkAuthState();
    const response = await this.get(`/requests/${id}`);
    if (response.data.actions === undefined) response.data.actions = [];
    return response;
  }

  /**
   * Returns the credentials of the current user.
   */
  async getCredentials(did) {
    this.checkAuthState();
    const url = did
      ? `/credentials?did=${did}&status=VALID`
      : '/credentials?status=VALID';
    const response = await this.get(url);
    return response;
  }
}
