import * as React from 'react';

export const DescriptionGen = () => {
    const important = 'Die Excel Tabelle muss eine Spalte mit dem Bezeichner „crefoId“ beinhalten. Hier ist auf die genaue Schreibweise zu Achten!';
  const first =
    'Die Excel Datei muss im csv Format gespeichert werden - Datei —> Speichern unter —> Dateiformat auswählen —> Kommagetrennte Werte (.csv) —> Speichern';
  const second = 'Im Feld oben nach einem Verifier suchen, einen der Gefundenen auswählen und im Anschluss auch den Use Case auswählen, dann auf "Save" drücken zum generieren.'; 
    const third =
    'Nach dem generieren die Datei importieren - Datei —> Importieren —> CSV-Datei —> Importieren —> Datei auswählen —> Datenursprung —> Unicode (UTF-8) —> Weiter —> Trennzeichen —> Semikolon —> Textqualifizierer —> " -> Fertig stellen';
  return (
        <dl>
            <dt>Wichtiger Hinweise</dt>
            <dd>{important}</dd>
            <dt>1. Schritt</dt>
            <dd>{first}</dd>
            <dt>2. Schritt</dt>
            <dd>{second}</dd>
            <dt>3. Schritt</dt>
            <dd>{third}</dd>
        </dl>   
  );
};
