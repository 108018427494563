/* eslint-disable no-unused-vars */
import React from 'react';

import { SimpleShowLayout, TextField } from 'react-admin';

import { UseCaseStyles } from './UseCaseList.style';

export const UseCaseDetail = (props) => {
  const style = UseCaseStyles()
  return (
    <div {...props}>
      <SimpleShowLayout>
        <TextField label='Use Case Details' textAlign='left' />
        <TextField source='webhook' label='Webhook' textAlign='left' className={style.rowOdd} />
        <TextField
          source='redirect.success'
          label='Redirect Success'
          textAlign='left'
        />
        <TextField
          source='redirect.failure'
          label='Redirect Failure'
          textAlign='left'
          className={style.rowOdd}
        />
        <TextField source='entryUrl' label='Entry Url' textAlign='left'  />
      </SimpleShowLayout>
    </div>
  );
};
