import React from 'react';

import PropTypes from 'prop-types';
import { DateField } from 'react-admin';

export const DateFieldForTimestamp = (props) => {
  const { source, record } = props;
  const recordWithTimestampInMs = {
    [source]: new Date(record[source]),
  };

  return (
    <DateField {...props} record={recordWithTimestampInMs} locales='de-DE' />
  );
};

DateFieldForTimestamp.defaultProps = {
  record: {},
  source: '',
};

DateFieldForTimestamp.propTypes = {
  record: PropTypes.shape({}),
  source: PropTypes.string,
};
