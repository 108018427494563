import React from 'react';

import { DateInput, Filter, TextInput } from 'react-admin';

export const PostFilter = (props) => (
  <Filter {...props}>
    <TextInput label='Unternehmen' source='legalName' />
    <TextInput label='Name' source='familyName' />
    <TextInput label='Vorname' source='givenName' />
    <DateInput label='Von' source='createdAt' alwaysOn />
    <DateInput label='Bis' source='toValueOfPeriodTimeFilter' alwaysOn />
  </Filter>
);
