import { makeStyles } from '@material-ui/core/styles';

export const OrderDetailStyle = makeStyles({
  textField: {
    marginTop: '10px',
  },
  button: {
    paddingLeft: '0px',
  },
});
