import React from 'react';

import { List, Datagrid, TextField, FunctionField } from 'react-admin';

import { CustomArrayChipField } from '../custom-array-chip-field/CustomArrayChipField';
import { DateFieldForTimestamp } from '../date-field-for-timestamp/DateFieldForTimestamp';
import { PersonsFilter } from './PersonsFilter';

// eslint-disable-next-line react/prop-types
const NameField = ({ record }) => {
  // eslint-disable-next-line react/prop-types
  const { givenName, familyName } = record?.person;
  // eslint-disable-next-line react/prop-types
  const { name: accountName } = record?.account;
  let name = '';
  if (givenName && familyName) {
    name = `${givenName} ${familyName}`;
  } else {
    name = accountName;
  }
  return <span>{name}</span>;
};

// actions={<CustomActionBar />} expand={<PersonDetail />}
export const PersonsList = (props) => (
  <List
    {...props}
    filters={<PersonsFilter />}
    bulkActionButtons={false}
    title='Personen'
    sort={{ field: 'createdAt', order: 'DESC' }}>
    <Datagrid>
      <TextField source='account.username' label='CRID' textAlign='left' />
      <NameField label='Name' textAlign='left' sortBy='givenName' />
      <TextField
        source='person.crefoId'
        label='Crefo-Nummer'
        textAlign='left'
      />
      <FunctionField
        label='Account-Status'
        textAlign='left'
        sortBy='verified'
        render={(record) => {
          const dataSources = [...(record.dataSource || [])];
          if (dataSources.length > 0) {
            return `${record.account.verified} [${dataSources.join('|')}]`;
          }
          return `${record.account.verified}`;
        }}
      />
      <TextField label='E-Mail' source='email' textAlign='left' />
      <TextField source='account.did' label='DID' textAlign='left' />

      <CustomArrayChipField
        source='credentials'
        label='Ausgestellte Personen VCs'
        textAlign='left'
        limit={2}
        type='persons'
        sortable={false}
      />
      <DateFieldForTimestamp source='createdAt' label='Erstellt' showTime />
    </Datagrid>
  </List>
);

/**
 *
 * <TextField source='requestId' label='Request-ID' textAlign='left' />
 * <FunctionField
 *         label='ExternalOrderIDs (Ident)'
 *         textAlign='left'
 *         sortBy='externalOrderId'
 *         render={(record) => {
 *           const externalOrderIds = [...record.externalOrderId];
 *           if (externalOrderIds.length > 0 && externalOrderIds[0]) {
 *             return `[${externalOrderIds.join('|')}]`;
 *           }
 *           return '';
 *         }}
 *       />
 *       <FunctionField
 *         label='Status (Ident)'
 *         textAlign='left'
 *         sortable={false}
 *         render={(record) => {
 *           const requestStates = [...record.requestState];
 *           if (requestStates.length > 0) {
 *             return `[${requestStates.join('|')}]`;
 *           }
 *           return '';
 *         }}
 *       />
 * */
